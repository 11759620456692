import React from 'react';
import { Modal } from 'bootstrap';
import './App.scss';
import './ModalDescriptions.scss';

import CloseIcon from './assets/x.svg';
import ProfilePic from './assets/profile_square.png';
import SmartCone1 from './assets/smart-cone-1.png';
import SmartCone2 from './assets/smart-cone-2.png';
import Plasma1 from './assets/plasma-1.jpg';
import Plasma2 from './assets/plasma-2.jpg';
import SolarCounter1 from './assets/solar-counter-1.png';
import SolarCounter2 from './assets/solar-counter-2.png';
import SolarCounterPhoto from './assets/solar-counter-photo.png';
import BeesProject1 from './assets/bees-project-1.png';
import BeesProject2 from './assets/bees-project-2.png';
import M2CEreception1 from './assets/m2c-ereception1.png';
import DagSword from './assets/dag-sword.png';

export default class App extends React.Component {
    constructor(props) {
        super(props);

        this.smartConeModal = null;
        this.ntPlasmaModal = null;
        this.solarCounterModal = null;
        this.moduloBeesModal = null;
        this.ereceptionModal = null;
        this.dagSwordModal = null;
    }

    componentDidMount() {
        this.smartConeModal = new Modal(document.getElementById('smart-cone-modal'));
        this.ntPlasmaModal = new Modal(document.getElementById('nt-plasma-modal'));
        this.solarCounterModal = new Modal(document.getElementById('solar-counter-modal'));
        this.moduloBeesModal = new Modal(document.getElementById('modulo-bees-modal'));
        this.ereceptionModal = new Modal(document.getElementById('ereception-modal'));
        this.dagSwordModal = new Modal(document.getElementById('dag-sword-modal'));
    }

    renderProjectsGroup() {
        return (
            <div>
                <h4 className="mb-4">Professional work:</h4>
                <div className="row">
                    <div className={'col-lg-4 col-md-6 col-sm-12 pb-4'}>
                        <div className="project-card" onClick={() => this.smartConeModal.show()}>
                            <img className="thumbnail" src={SmartCone1} alt="Smart cone project thumbnail"></img>
                            <p className="title ps-3 py-1 mb-0">Smart Cone</p>
                        </div>
                    </div>

                    <div className={'col-lg-4 col-md-6 col-sm-12 pb-4'}>
                        <div className="project-card" onClick={() => this.ntPlasmaModal.show()}>
                            <img className="thumbnail plasma" src={Plasma2} alt="NT Plasma project thumbnail"></img>
                            <p className="title ps-3 py-1 mb-0">Non-Thermal Plasma</p>
                        </div>
                    </div>

                    <div className={'col-lg-4 col-md-6 col-sm-12 pb-4'}>
                        <div className="project-card" onClick={() => this.solarCounterModal.show()}>
                            <img
                                className="thumbnail mybikecounter"
                                src={SolarCounter2}
                                alt="My Bike Counter project thumbnail"
                            ></img>
                            <p className="title ps-3 py-1 mb-0">My Bike Counter</p>
                        </div>
                    </div>

                    <div className={'col-lg-4 col-md-6 col-sm-12 pb-4'}>
                        <div className="project-card" onClick={() => this.moduloBeesModal.show()}>
                            <img
                                className="thumbnail bees"
                                src={BeesProject2}
                                alt="Modulo Bees project thumbnail"
                            ></img>
                            <p className="title ps-3 py-1 mb-0">Modulo Bees</p>
                        </div>
                    </div>

                    <div className={'col-lg-4 col-md-6 col-sm-12 pb-4'}>
                        <div className="project-card" onClick={() => this.ereceptionModal.show()}>
                            <img
                                className="thumbnail ereception"
                                src={M2CEreception1}
                                alt="E-reception project thumbnail"
                            ></img>
                            <p className="title ps-3 py-1 mb-0">M2C E-reception</p>
                        </div>
                    </div>
                </div>
                <hr />
                <h4 className="mb-3">Academic work:</h4>
                <div className="row">
                    <div className={'col-lg-4 col-md-6 col-sm-12 pb-4'}>
                        <div className="project-card" onClick={() => this.dagSwordModal.show()}>
                            <img className="thumbnail ereception" src={DagSword} alt="DagSword project thumbnail"></img>
                            <p className="title ps-3 py-1 mb-0">
                                DAG-Sword: Simulator of Large-Scale Network Topologies for DAG-Oriented Proof-of-Work
                                Blockchains
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderSmartConeModal() {
        return (
            <div>
                <div className={'modal fade'} id={'smart-cone-modal'} tabIndex={'-1'} role={'dialog'}>
                    <div className={'modal-dialog modal-xl'}>
                        <div className={'modal-content'}>
                            <div className={'modal-header px-4'}>
                                <div className="d-flex flex-column w-100">
                                    <div className="d-flex flex-row">
                                        <h2 className={'modal-title'}>Smart Cone</h2>
                                        <div className="vr"></div>
                                        <div className="tags my-auto me-auto">
                                            <span className="ui-button me-2">Terraform</span>
                                            <span className="ui-button me-2">Nest.js</span>
                                            <span className="ui-button me-2">React</span>
                                            <span className="ui-button me-2">RabbitMQ</span>
                                            <span className="ui-button me-2">PostgreSQL</span>
                                            <span className="ui-button me-2">MongoDB</span>
                                            <span className="ui-button me-2">Redis</span>
                                            <span className="ui-button">Docker</span>
                                        </div>
                                        <div className={'circle-button'} onClick={() => this.smartConeModal.hide()}>
                                            <img src={CloseIcon} alt={'Close icon'} />
                                        </div>
                                    </div>
                                    <div className="mt-3">
                                        <p>
                                            A system that utilizes camera devices to count cars, processes the data on a
                                            server, and visualizes it on a real-time dashboard.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className={'modal-body px-4'}>
                                <div className="row">
                                    <div className="col-lg-7">
                                        <img
                                            className="img-smart-cone-1 w-100"
                                            src={SmartCone1}
                                            alt="Dashboard overview"
                                        ></img>
                                    </div>
                                    <div className="col-lg-5">
                                        <h4>Realtime parking lot overview</h4>
                                        <ul>
                                            <li>Displays real-time car counts for each parking lot.</li>
                                            <li>Shares data across all connected users.</li>
                                            <li>Activates user-set alerts when capacity limits are reached.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="mt-4 mb-2 row">
                                    <div className="col-lg-5">
                                        <h4>Dynamic management</h4>
                                        <ul>
                                            <li>
                                                Allows dynamic adjustment of parking lot capacity, fulfillment status,
                                                and warning/error thresholds via the dashboard.
                                            </li>
                                            <li>Changes are applied instantly to all connected users.</li>
                                            <li>
                                                Successfully deployed at the RedBull Motocross event in summer 2023.
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-7">
                                        <img
                                            className="img-smart-cone-2 w-100"
                                            src={SmartCone2}
                                            alt="Dashboard editing"
                                        ></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderNtPlasmaModal() {
        return (
            <div>
                <div className={'modal fade'} id={'nt-plasma-modal'} tabIndex={'-1'} role={'dialog'}>
                    <div className={'modal-dialog modal-xl'}>
                        <div className={'modal-content'}>
                            <div className={'modal-header px-4'}>
                                <div className="d-flex flex-column w-100">
                                    <div className="d-flex flex-row">
                                        <h2 className={'modal-title'}>Non-Thermal Plasma, tablet app</h2>
                                        <div className="vr"></div>
                                        <div className="tags my-auto me-auto">
                                            <span className="ui-button me-2">React Native</span>
                                            <span className="ui-button me-2">Bluetooth communication</span>
                                            <span className="ui-button me-2">Expo</span>
                                            <span className="ui-button">Android</span>
                                        </div>
                                        <div className={'circle-button'} onClick={() => this.ntPlasmaModal.hide()}>
                                            <img src={CloseIcon} alt={'Close icon'} />
                                        </div>
                                    </div>
                                    <div className="mt-3">
                                        <p>
                                            A tablet application designed to control and communicate with a
                                            custom-engineered device for medical and skincare purposes.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className={'modal-body px-4'}>
                                <div className="row">
                                    <div className="col-lg-7">
                                        <img
                                            className="img-nt-plasma-1 w-100"
                                            src={Plasma2}
                                            alt="Plasma app and device"
                                        ></img>
                                    </div>
                                    <div className="col-lg-5">
                                        <h4>Bluetooth control</h4>
                                        <ul>
                                            <li>App communicates with the device remotely via Bluetooth.</li>
                                            <li>Displays progress updates and error messages related to procedures.</li>
                                            <li>
                                                Regularly checks the device connection and automatically reconnects if
                                                the device is restarted.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="mt-4 mb-2 row">
                                    <div className="col-lg-5">
                                        <h4>User-defined procedures</h4>
                                        <ul>
                                            <li>
                                                Allows users to start procedure programs, adjust settings, and review
                                                usage history to calculate expenses.
                                            </li>
                                            <li>Creation of custom procedure programs.</li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-7">
                                        <img
                                            className="img-nt-plasma-2 w-100"
                                            src={Plasma1}
                                            alt="Plasma application"
                                        ></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderSolarCounterModal() {
        return (
            <div>
                <div className={'modal fade'} id={'solar-counter-modal'} tabIndex={'-1'} role={'dialog'}>
                    <div className={'modal-dialog modal-xl'}>
                        <div className={'modal-content'}>
                            <div className={'modal-header px-4'}>
                                <div className="d-flex flex-column w-100">
                                    <div className="d-flex flex-row">
                                        <h2 className={'modal-title'}>My Bike Counter</h2>
                                        <div className="vr"></div>
                                        <div className="tags my-auto me-auto">
                                            <span className="ui-button me-2">Nest.js</span>
                                            <span className="ui-button me-2">React</span>
                                            <span className="ui-button me-2">Redis</span>
                                            <span className="ui-button me-2">Rest API</span>
                                            <span className="ui-button me-2">Discord bot</span>
                                        </div>
                                        <div className={'circle-button'} onClick={() => this.solarCounterModal.hide()}>
                                            <img src={CloseIcon} alt={'Close icon'} />
                                        </div>
                                    </div>
                                    <div className="mt-3">
                                        <p>
                                            A system that processes data of cyclists, pedestrians, cars, and trucks in
                                            cities using continuously deployed devices, providing hourly counts,
                                            visualizations, and data access via direct download or API.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className={'modal-body px-4'}>
                                <div className="row">
                                    <div className="col-lg-7">
                                        <img
                                            className="img-solar-counter-1 w-100"
                                            src={SolarCounter2}
                                            alt="My Bike Counter daily chart"
                                        ></img>
                                    </div>
                                    <div className="col-lg-5">
                                        <h4>User dashboard</h4>
                                        <ul>
                                            <li>Processes data from deployed devices.</li>
                                            <li>
                                                Analyzes data on the dashboard with interactive charts with different
                                                time intervals.
                                            </li>
                                            <li>Data queries for further processing.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="mt-4 mb-2 row">
                                    <div className="col-lg-5">
                                        <h4>Microservice architecture</h4>
                                        <ul>
                                            <li>API implementation for direct data reception.</li>
                                            <li>Discord bot for device status messages.</li>
                                            <li>Over 20 deployed devices across 3 cities in Slovakia.</li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-7">
                                        <img
                                            className="img-solar-counter-2 w-100"
                                            src={SolarCounter1}
                                            alt="My Bike Counter device data overview"
                                        ></img>
                                    </div>
                                </div>
                                <div className="modal-footer d-flex flex-row justify-content-center">
                                    <img
                                        className="img-solar-counter-photo"
                                        src={SolarCounterPhoto}
                                        alt="My Bike Counter device in Bratislava"
                                    ></img>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderModuloBeesModal() {
        return (
            <div>
                <div className={'modal fade'} id={'modulo-bees-modal'} tabIndex={'-1'} role={'dialog'}>
                    <div className={'modal-dialog modal-xl'}>
                        <div className={'modal-content'}>
                            <div className={'modal-header px-4'}>
                                <div className="d-flex flex-column w-100">
                                    <div className="d-flex flex-row">
                                        <h2 className={'modal-title'}>Modulo Bees</h2>
                                        <div className="vr"></div>
                                        <div className="tags my-auto me-auto">
                                            <span className="ui-button me-2">PHP</span>
                                            <span className="ui-button me-2">Symfony</span>
                                            <span className="ui-button me-2">React</span>
                                        </div>
                                        <div className={'circle-button'} onClick={() => this.moduloBeesModal.hide()}>
                                            <img src={CloseIcon} alt={'Close icon'} />
                                        </div>
                                    </div>
                                    <div className="mt-3">
                                        <p>
                                            A platform for receiving data from IoT devices, triggering functions within
                                            a dashboard, and transmitting data to other devices.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className={'modal-body px-4'}>
                                <div className="row">
                                    <div className="col-lg-7">
                                        <img
                                            className="img-bees-project-1 w-100"
                                            src={BeesProject2}
                                            alt="Modulo bees dashboard"
                                        ></img>
                                    </div>
                                    <div className="col-lg-5">
                                        <h4>Custom widgets for dashboard</h4>
                                        <ul>
                                            <li>Create custom dashboards and connect IoT devices.</li>
                                            <li>Allows creation of custom widgets with various variable types.</li>
                                            <li>
                                                Enables creation of triggers (events) based on specific received
                                                messages.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="mt-4 mb-2 row">
                                    <div className="col-lg-5">
                                        <h4>Interactive device management</h4>
                                        <ul>
                                            <li>
                                                Check logs from devices, modify received data variable types, and upload
                                                updates.
                                            </li>
                                            <li>
                                                Tested on two bachelor thesis projects authored by students from STU
                                                FIIT.
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-7">
                                        <img
                                            className="img-bees-project-2 w-100"
                                            src={BeesProject1}
                                            alt="Modulo device log screen on dashboard"
                                        ></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderEreceptionModal() {
        return (
            <div>
                <div className={'modal fade'} id={'ereception-modal'} tabIndex={'-1'} role={'dialog'}>
                    <div className={'modal-dialog modal-xl'}>
                        <div className={'modal-content'}>
                            <div className={'modal-header px-4'}>
                                <div className="d-flex flex-column w-100">
                                    <div className="d-flex flex-row">
                                        <h2 className={'modal-title'}>M2C E-reception</h2>
                                        <div className="vr"></div>
                                        <div className="tags my-auto me-auto">
                                            <span className="ui-button me-2">React Native</span>
                                            <span className="ui-button me-2">Symfony</span>
                                            <span className="ui-button me-2">Google Firebase</span>
                                            <span className="ui-button me-2">WebRTC</span>
                                            <span className="ui-button me-2">Android</span>
                                            <span className="ui-button me-2">iOS</span>
                                        </div>
                                        <div className={'circle-button'} onClick={() => this.ereceptionModal.hide()}>
                                            <img src={CloseIcon} alt={'Close icon'} />
                                        </div>
                                    </div>
                                    <div className="mt-3">
                                        <p>
                                            A mobile application that allows users to call a property owner who can
                                            remotely unlock a door. Users can also receive one-time invitations from the
                                            owner to unlock the door themselves.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className={'modal-body px-4'}>
                                <div className="row mb-2">
                                    <div className="col-lg-6">
                                        <img
                                            className="img-ereception-1 w-100"
                                            src={M2CEreception1}
                                            alt="Ereception login screen"
                                        ></img>
                                    </div>
                                    <div className="col-lg-6">
                                        <h4>Unlock Doors via Smartphone or One-Time Invitations</h4>
                                        <ul>
                                            <li>
                                                Users can initiate internet video calls for remote door opening by
                                                property owners.
                                            </li>
                                            <li>
                                                Property owners receive notifications about entries and can send
                                                one-time invitations to others.
                                            </li>
                                            <li>
                                                Property owners manage the system through a dedicated website dashboard.
                                            </li>
                                            <li>Video calls utilize WebRTC technology.</li>
                                            <li>Accessible on both Android and iOS platforms.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderDagSwordModal() {
        return (
            <div>
                <div className={'modal fade'} id={'dag-sword-modal'} tabIndex={'-1'} role={'dialog'}>
                    <div className={'modal-dialog modal-xl'}>
                        <div className={'modal-content'}>
                            <div className={'modal-header px-4'}>
                                <div className="d-flex flex-column w-100">
                                    <div className="d-flex flex-row">
                                        <h2 className={'modal-title dag-sword-title'}>
                                            Simulator of Large-Scale Network Topologies for DAG-Oriented Proof-of-Work
                                            Blockchains
                                        </h2>
                                        <div className="vr"></div>
                                        <div className="tags my-auto me-auto">
                                            <span className="ui-button me-2">C++</span>
                                            <span className="ui-button me-2">Discrete-event simulator</span>
                                            <span className="ui-button me-2">Python</span>
                                        </div>
                                        <div className={'circle-button'} onClick={() => this.dagSwordModal.hide()}>
                                            <img src={CloseIcon} alt={'Close icon'} />
                                        </div>
                                    </div>
                                    <div className="mt-3">
                                        <p>
                                            A study on improving transaction throughput in DAG-based consensus protocols
                                            and mempool optimizations using discrete-event simulator.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className={'modal-body px-4'}>
                                <div className="row mb-2">
                                    <div className="col-lg-6">
                                        <img
                                            className="img-dag-sword-1 w-100"
                                            src={DagSword}
                                            alt="Example of DAG oriented blockchain"
                                        ></img>
                                    </div>
                                    <div className="col-lg-6">
                                        <h4>Blockchain network simulation</h4>
                                        <ul>
                                            <li>
                                                Development of a discrete-event simulator for blockchain networks with
                                                focus on DAG-based consensus protocols.
                                            </li>
                                            <li>Customizable network configuration and topology.</li>
                                            <li>
                                                Simulation of realistic blockchain networks using data from the Bitcoin
                                                network.
                                            </li>
                                            <li>Analysis of the impact of malicious miners on protocol performance.</li>
                                            <li>Experiments with different algorithms for the mempool structure.</li>
                                            <li>
                                                Proposal of a composite mempool structure for efficient simulation of
                                                large-scale networks.
                                            </li>
                                            <li>
                                                Presented at: 57th Hawaii International Conference on System Sciences.
                                            </li>
                                            <li>
                                                Available at:
                                                <a
                                                    className="ms-1"
                                                    target="_"
                                                    href="https://hdl.handle.net/10125/107101"
                                                >
                                                    https://hdl.handle.net/10125/107101
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <>
                <main>
                    <div className="mb-5">
                        {this.renderSmartConeModal()}
                        {this.renderNtPlasmaModal()}
                        {this.renderSolarCounterModal()}
                        {this.renderModuloBeesModal()}
                        {this.renderEreceptionModal()}
                        {this.renderDagSwordModal()}
                        <div className="container-xxl main-container">
                            <div className="d-flex flex-row justify-content-evenly">
                                <div className="d-flex flex-row">
                                    <div className="d-flex flex-column profile-image-container">
                                        <img
                                            className="profile-pic"
                                            src={ProfilePic}
                                            width={180}
                                            height={180}
                                            alt="Tomas profile"
                                        ></img>
                                        <div className="d-flex flex-row justify-content-evenly mt-3">
                                            <a
                                                className="social-media-icon"
                                                href="https://www.linkedin.com/in/tomashladky/"
                                                target="_"
                                            >
                                                <ion-icon name="logo-linkedin"></ion-icon>
                                            </a>
                                            <a
                                                className="social-media-icon"
                                                href="https://www.instagram.com/tomas_hladky99/"
                                                target="_"
                                            >
                                                <ion-icon name="logo-instagram" class="social-media-icon"></ion-icon>
                                            </a>
                                            <a className="social-media-icon" href="https://github.com/Tem12" target="_">
                                                <ion-icon name="logo-github" class="social-media-icon"></ion-icon>
                                            </a>
                                            <a className="social-media-icon" href="mailto:hladky@modulosys.com">
                                                <ion-icon name="mail-outline" class="social-media-icon"></ion-icon>
                                            </a>
                                        </div>
                                        <a href="tomas-hladky-cv.pdf" target="_" className="primary-button mt-3">
                                            <ion-icon
                                                name="arrow-down-outline"
                                                class="arrow-down-outline-button"
                                            ></ion-icon>{' '}
                                            Download my CV
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <h1 className="col-lg-6 main-headline">Tomas Hladky</h1>
                                    <p>
                                        I am a master's degree student of Information Technology specializing in
                                        cybersecurity. Since high school, I have been developing web applications from
                                        scratch, including graphic design, front-end, and back-end, with an emphasis on
                                        creating secure microservice architecture and well-structured API. Creating
                                        easy-to-use, user-friendly applications has always been my passion.
                                    </p>

                                    <p>Feel free to explore my portfolio below and do not hesitate to get in touch!</p>
                                </div>
                            </div>
                            <hr />
                            {this.renderProjectsGroup()}
                        </div>
                    </div>
                </main>
                <footer className="footer-block text-center">© 2024 Tomas Hladky</footer>
            </>
        );
    }
}
